.pac-container {
    background-color: white;
    position: absolute !important;
    z-index: 1000;
    box-shadow: 0px 4px 24px 0px rgba(0, 31, 63, 0.12);
    border-radius: 8px;
    margin-top: 10px;
    border-top: 1px solid #CFD8DC;
    font-family: 'Nunito Sans', sans-serif;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    overflow: hidden
}

.pac-logo:after {
    content: "";
    padding: 1px 1px 1px 0;
    height: 0px;
    text-align: right;
    display: block;
    background-image: none
}

.hdpi.pac-logo:after {
    background-image: none
}
.pac-item {
    cursor: default;
    padding: 4px 10px !important;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    line-height: 30px;
    text-align: left;
    border-top: none !important;
    font-size: 12px;
    color: #001E00;
}

.pac-item:hover .pac-item-query {
  color: white !important;
}

.pac-item:hover {
    cursor: pointer;
    color: white !important;
    background-color: #61BC5A
}
.pac-item-selected,
.pac-item-selected:hover {
    color: white;
    background-color: #61BC5A
}
.pac-matched {
    font-weight: 400
}
.pac-item-query {
    font-size: 16px;
    padding-right: 10px;
    color: #001E00
}
.pac-item-query:hover {
  color: white !important
}
.pac-icon {
    width: 15px;
    height: 20px;
    margin-right: 10px;
    margin-left: 10px;
    margin-top: 6px;
    display: inline-block;
    vertical-align: top;
    background-image: url(https://maps.gstatic.com/mapfiles/api-3/images/autocomplete-icons.png);
    background-size: 34px
}
.hdpi .pac-icon {
    background-image: url(https://maps.gstatic.com/mapfiles/api-3/images/autocomplete-icons_hdpi.png)
}
.pac-icon-search {
    background-position: -1px -1px
}
.pac-item-selected .pac-icon-search {
    background-position: -18px -1px
}
.pac-item-selected,
.pac-item-selected:hover {
    color: white;
    background-color: #61BC5F;
}

.pac-item-selected .pac-item-query {
  color: white !important;
}

.pac-icon-marker {
    background-position: -1px -161px
}
.pac-item-selected .pac-icon-marker {
    background-position: -18px -161px
}
.pac-placeholder {
    color: gray
}