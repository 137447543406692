@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html, body {
    font-family: 'Nunito Sans';
    max-width: 100%;
    overflow-x: hidden;
  }

  .PhoneInput {
    border: 1px solid #D3E3F1;
    border-radius: 4px;
  }

  .card-shadow {
    box-shadow: 0px 4px 24px 0px rgba(18, 50, 88, 0.12);
  }

  .popup-shadow {
    box-shadow: 0px 4px 24px 0px rgba(76, 103, 128, 0.16);
  }

  .search-shadow {
    box-shadow: 0px 6px 24px 0px rgba(18, 50, 88, 0.08);
  }

  .main-search-shadow {
    box-shadow: 0px 4px 24px 0px rgba(0, 31, 63, 0.12);
  }

  .appointment-card-shadow {
    box-shadow: 0px 4px 24px 0px rgba(18, 50, 88, 0.10);
  }

  .notes-list li::before {
    content: "\2022";
    display: inline-block;
    width: 1em; 
    margin-left: -1em; 
    color: #26B6A6;
  }
  
  .button-shadow {
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
  }
  
  .form-shadow {
    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.10);
  }

  .pac-container:after {
    display:none !important;
    border: none
  }

  .pac-container .pac-item {
    border: none;
    margin-top: 10px;
  }

  .pac-container .pac-item:hover {
    background: #123258;
    color: white;
  }

  .PhoneInputInput{
    outline: none !important;
    border:none !important;
    box-shadow: none !important;
  }

  .PhoneInput .PhoneInputCountry{
    background-color: rgba(127, 154, 178, 0.10);
    padding: 0px 4px 0px 12px;
  }

  .PhoneInput .PhoneInputCountrySelectArrow {
    margin-left: 6px;
    margin-right: 6px;
  }

  .datacenterBackground {
    background-position-x: -490px 
  }

  .articleContent h1 {
    font-size: 28px;
    color: #123258;
    font-weight: 700;
    position: relative;
    padding-left: 50px;
    margin-bottom: 16px;
  }
  
  .articleContent h1::before {
    content: ''; 
    display: block; 
    width: 25px; 
    height: 25px; 
    background-image: url('assets/pngs/bullet.png'); 
    background-size: cover; 
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  .articleContent p {
    color: #4C6780
  }

  .articleContent ul {
    padding-left: 18px;
  }

  .articleContent li {
    color: #4C6780;
    list-style-type: disc
  }

  @media (max-width: 1024px) {
    .articleContent h1 {
      font-size: 20px
    }
  }

  input[type='date']::-webkit-calendar-picker-indicator {
    background: transparent;
    color: transparent;
    cursor: pointer
  }

  .RSPBprogressBar .RSPBstep {
    position: static !important;
    transform: translateX(0%);
  }

  .container-shadow {
    box-shadow: 0px 4px 24px 0px rgba(127, 154, 178, 0.25);
  }

  .curved-line {
    backface-visibility: hidden;
    background: linear-gradient(45deg,#001367,#107aca);
    clip-path: ellipse(98% 110% at 99% 70%);
  }


.faq h1 {
	display: block;
	font-size: 2em;
	margin-top: 0.67em;
	margin-bottom: 0.67em;
	margin-left: 0;
	margin-right: 0;
	font-weight: bold;
}

.faq h2 {
	display: block;
	font-size: 1.5em;
	margin-top: 0.83em;
	margin-bottom: 0.83em;
	margin-left: 0;
	margin-right: 0;
	font-weight: bold;
}

.faq h3 {
	display: block;
	font-size: 1.17em;
	margin-top: 1em;
	margin-bottom: 1em;
	margin-left: 0;
	margin-right: 0;
	font-weight: bold;
}

.faq a {
	color: blue;
	text-decoration: underline;
}

.faq a:visited {
	color: purple;
}

.faq a:hover {
	cursor: pointer;
}

.faq a:active {
	color: red;
}

.faq ul,
.faq ol {
	display: block;
	margin: 1em 0;
	padding: 0 0 0 10px;
}

.faq ul {
	list-style-type: disc;
	list-style-position: inside;
}

.faq ol {
	list-style-type: decimal;
	list-style-position: inside;
}

.custom-image-gallery .image-gallery-image {
  width: 500px; 
  height: 500px;
  margin-left: auto;
  margin-right: auto;
  object-fit: cover; 
}

@media only screen and (max-width: 1024px) {
  .custom-image-gallery .image-gallery-image {
    width: 300px; 
    height: 300px;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

  @layer components {
    .tw-table-td {
      @apply text-sm p-3 text-primary text-center font-semibold border-t border-b border-[#26B6A64D]
    }
    .tw-table-th {
      @apply p-3 text-center text-base font-semibold text-white bg-primary
    }
    .tw-table-span {
      @apply inline-flex rounded-full px-3 font-normal p-1 text-base leading-5 text-white
    }  
  }

}